import React, { useState } from "react";
import { motion } from "framer-motion";
import { useLocationQuery } from "../../GraphQl/useLocationQuery";
import { useDepartmentQuery } from "../../GraphQl/useDepartmentQuery";
import { useEmploymentTypeQuery } from "../../GraphQl/useEmploymentTypeQuery";
import { useNavQuery } from "@querys/useNavQuery";
import { useSiteContext, useFilters } from "@lib/context";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";

const variants = {
  initial: {
    opacity: 0,
    y: 40,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const OppSelect = () => {
  const { state } = useSiteContext();
  const { updateLocation, updateDepartment, updateType } = useFilters();
  const { locations } = useLocationQuery();
  const { departments } = useDepartmentQuery();
  const { types } = useEmploymentTypeQuery();
  const { filterTitle, filterSubtitle } = useNavQuery();

  const selectStyles = `blockH7 input-field h-[50px] 2xl:h-[76px] custom-select `;
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      id="opportunities"
      className="py-[60px] lg:py-[100px] bg-blue px-10 sm:px-40 md:px-60 lg:px-10  2xl:px-[180px]"
    >
      <h1 className="blockH1 text-orange text-center">{filterTitle}</h1>
      <p className="blockH4 text-white text-center">{filterSubtitle}</p>
      <motion.div
        variants={variants}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        className="col-start-1 col-end-4  mt-12 flex flex-col lg:grid  grid-cols-3 items-center justify-center  gap-x-12 gap-y-6 "
      >
        <select
          className={selectStyles}
          onChange={(e) => {
            e.target.value === "default"
              ? updateDepartment("default")
              : updateDepartment(departments?.[e.target.value]?.departmentName);
          }}
        >
          <option value="default">All disciplines</option>
          {departments?.map((item, i) => (
            <option key={i} value={i}>
              {item?.departmentName}
            </option>
          ))}
        </select>
        <select
          className={selectStyles}
          onChange={(e) => {
            e.target.value === "default"
              ? updateType("default")
              : updateType(types?.[e.target.value]?.employmentTypeName);
          }}
        >
          <option value="default">All employment types</option>
          {types?.map((item, i) => (
            <option key={i} value={i}>
              {item?.employmentTypeName}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => {
            e.target.value === "default"
              ? updateLocation("default")
              : updateLocation(locations[e.target.value]?.locationName);
          }}
          className={selectStyles}
        >
          <option value="default">All locations</option>
          {locations?.map((item, i) => (
            <option key={i} value={i}>
              {item?.locationName}
            </option>
          ))}
        </select>
      </motion.div>
      <motion.div
        variants={variants}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        className="mt-16 text-center"
      >
        <Link
          to="/opportunities"
          className="button-orange  2xl:!px-40 !py-3 2xl:!py-5"
        >
          Let's go
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default OppSelect;

import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const ImageText = loadable(() => import("./modules/imageText"));
const AnimatedBanner = loadable(() => import("./modules/animatedBanner"));
const TwoImageText = loadable(() => import("./modules/twoImageText"));
const TextBlock = loadable(() => import("./modules/textBlock"));
const VideoText = loadable(() => import("./modules/videoText"));
const CardBlock = loadable(() => import("./modules/cardBlock"));
const ThreeImageText = loadable(() => import("./modules/threeImageText"));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case "imageText":
        return <ImageText {...item} />;
      case "animatedBanner":
        return <AnimatedBanner {...item} />;
      case "twoImageText":
        return <TwoImageText {...item} />;
      case "textBlock":
        return <TextBlock {...item} />;
      case "videoText":
        return <VideoText {...item} />;
      case "cardBlock":
        return <CardBlock {...item} />;
      case "threeImageText":
        return <ThreeImageText {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;

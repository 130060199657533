import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import OppSelect from "@components/other/oppSelect";

const IndexPage = ({ data: { page } }) => {
  const { seo, modules } = page || {};
  const [loading, setLoading] = React.useState(false);

  // set timeout for 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
      {loading && <OppSelect />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query careersQuery {
    page: sanityCareers {
      seo {
        ...SEO
      }
      title
      modules {
        ...CareerModules
      }
    }
  }
`;
